import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function MenuList() {
    const classes = useStyles();

    const [familias, setFamilias] = React.useState(false);
    const [artigos, setArtigos] = React.useState(false);
    const [clientes, setClientes] = React.useState(false);
    const [documentos, setDocumentos] = React.useState(false);

    const handleClickFamilias=()=>{
        setFamilias(!familias);
    }

    const handleClickArtigos=()=>{
        setArtigos(!artigos);
    }

    const handleClickClientes=()=>{
        setClientes(!clientes);
    }

    const handleClickDocumentos=()=>{
        setDocumentos(!documentos);
    }

    return(
        <>
            <List>
                <Link to={"/dashboard"}>
                    <ListItem button>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                </Link>
                <Divider />
                <List>
                    <ListItem button onClick={handleClickDocumentos}>
                        <ListItemText primary="Encomendas" />
                    </ListItem>
                    <Collapse in={documentos} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to={"/documentos"}>
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Criar encomenda" />
                                </ListItem>
                            </Link>
                        </List>
                        <List component="div" disablePadding>
                            <Link to={"/documentos/teclado"}>
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Criar encomenda teclado" />
                                </ListItem>
                            </Link>
                        </List>
                        <List component="div" disablePadding>
                            <Link to={"/documentos/lista/pagos"}>
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Listar encomendas pagas" />
                                </ListItem>
                            </Link>
                        </List>
                        <List component="div" disablePadding>
                            <Link to={"/documentos/lista/pendente"}>
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Listar encomendas por pagar" />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                </List>
                <Divider />
                <Link to={"/pagamentos"}>
                    <ListItem button>
                        <ListItemText primary="Pagamentos" />
                    </ListItem>
                </Link>
                <Divider />
                <Link to={"/tara"}>
                    <ListItem button>
                        <ListItemText primary="Devolver taras" />
                    </ListItem>
                </Link>
                <Divider />
                <ListItem button onClick={handleClickClientes}>
                    <ListItemText primary="Clientes" />
                </ListItem>
                <Collapse in={clientes} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link to={"/clientes/criar"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Criar cliente" />
                            </ListItem>
                        </Link>
                    </List>
                    <List component="div" disablePadding>
                        <Link to={"/clientes"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Listar clientes" />
                            </ListItem>
                        </Link>
                    </List>
                </Collapse>
                <Divider />
                <ListItem button onClick={handleClickFamilias}>
                    <ListItemText primary="Familias" />
                </ListItem>
                <Collapse in={familias} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link to={"/familias/criar"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Criar familia" />
                            </ListItem>
                        </Link>
                    </List>
                    <List component="div" disablePadding>
                        <Link to={"/familias"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Listar familias" />
                            </ListItem>
                        </Link>
                    </List>
                </Collapse>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={handleClickArtigos}>
                    <ListItemText primary="Artigos" />
                </ListItem>
                <Collapse in={artigos} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link to={"/artigos/criar"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Criar artigos" />
                            </ListItem>
                        </Link>
                    </List>
                    <List component="div" disablePadding>
                        <Link to={"/artigos"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Listar artigos" />
                            </ListItem>
                        </Link>
                    </List>
                </Collapse>
            </List>
            <Divider />
            <Link to={"/utilizadores"}>
                <ListItem button>
                    <ListItemText primary="Utilizadores" />
                </ListItem>
            </Link>
        </>
    )
}
