import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import PrivateRoute from "./services/PrivateRoute";
import Login from "./pages/Login";
import FamiliaCriar from "./pages/familias/Criar";
import FamiliaListar from "./pages/familias/Listar";
import FamiliaEditar from "./pages/familias/Editar";
import ArtigosCriar from "./pages/artigos/Criar";
import ArtigoListar from "./pages/artigos/Listar";
import ArtigosEditar from "./pages/artigos/Editar";
import ArtigoListarTodos from "./pages/artigos/ListarTodos";
import FamiliaFicha from "./pages/familias/Ficha";
import ArtigoFicha from "./pages/artigos/Ficha";
import ClientesListar from "./pages/clientes/Listar";
import ClientesListarTodos from "./pages/clientes/ListarTodos";
import ClienteCriar from "./pages/clientes/Criar";
import ClienteFicha from "./pages/clientes/Ficha";
import ClienteEditar from "./pages/clientes/Editar";
import DocumentosCriar from "./pages/documentos/Criar";
import DocumentosListarPagos from "./pages/documentos/ListarPagos";
import DocumentosListarPorPagar from "./pages/documentos/ListarPorPagar";
import DocumentosVer from "./pages/documentos/VerDocumento";
import DocumentosEditar from "./pages/documentos/Editar";
import UtilizadoresListar from "./pages/utilizadores/Listar";
import UtilizadorFicha from "./pages/utilizadores/Ficha";
import DevolverTara from "./pages/taras/DevolverTara";
import Dashboard from "./pages/Dashboard";
import Pagamento from "./pages/pagamentos/Pagamento";
import DocumentosCriarTeclado from "./pages/documentos/CriarTeclado";
import {Auth} from "./pages/Auth";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/auth/:authToken" component={Auth} />

            <PrivateRoute exact path="/familias" component={FamiliaListar} />
            <PrivateRoute path="/familias/criar" component={FamiliaCriar} />
            <PrivateRoute path="/familias/editar/:id" component={FamiliaEditar} />
            <PrivateRoute exact path="/familias/:id" component={FamiliaFicha} />

            <PrivateRoute exact path="/artigos" component={ArtigoListar}/>
            <PrivateRoute exact path="/artigos/todos" component={ArtigoListarTodos}/>
            <PrivateRoute exact path="/artigos/criar" component={ArtigosCriar}/>
            <PrivateRoute exact path="/artigos/editar/:id" component={ArtigosEditar}/>
            <PrivateRoute exact path="/artigos/:id" component={ArtigoFicha}/>

            <PrivateRoute exact path="/clientes" component={ClientesListar} />
            <PrivateRoute exact path="/clientes/todos" component={ClientesListarTodos} />
            <PrivateRoute exact path="/clientes/criar" component={ClienteCriar} />
            <PrivateRoute exact path="/clientes/editar/:id" component={ClienteEditar} />
            <PrivateRoute exact path="/clientes/:id" component={ClienteFicha} />

            <PrivateRoute exact path="/documentos" component={DocumentosCriar} />
            <PrivateRoute exact path="/documentos/teclado" component={DocumentosCriarTeclado} />
            <PrivateRoute exact path="/documentos/lista/pagos" component={DocumentosListarPagos} />
            <PrivateRoute exact path="/documentos/lista/pendente" component={DocumentosListarPorPagar} />
            <PrivateRoute exact path="/documentos/ver/:id" component={DocumentosVer} />
            <PrivateRoute exact path="/documentos/editar/:id" component={DocumentosEditar} />

            <PrivateRoute exact path="/tara" component={DevolverTara} />
            <PrivateRoute exact path="/pagamentos" component={Pagamento} />

            <PrivateRoute exact path="/utilizadores" component={UtilizadoresListar} />
            <PrivateRoute exact path="/utilizadores/:id" component={UtilizadorFicha} />

            <PrivateRoute path="/dashboard" component={Dashboard} />
            <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
