import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";

import {Link} from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Paper} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '75hw',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ClienteFicha(props) {
    const classes = useStyles();
    const [data, setData] = useState({});
    const [compras, setCompras] = useState([]);
    const [contaCorrente, setContaCorrente] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [listaEncomendas, setListaEncomendas] = React.useState(false);
    const [listaTaras, setListaTaras] = React.useState(false);
    const [taras, setTaras] = React.useState([]);
    const [filtro, setFiltro] = useState("all");

    useEffect(()=>{
        api.get('/cliente/'+props.match.params.id).then(res=>{
            setData(res.data.data.data[0])
            setContaCorrente(res.data.data.contaCorrente)
            setCompras(res.data.data.compras)
            setTaras(res.data.data.contaCorrenteTaras)
        })
    }, [props.match.params.id])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Cliente: "+data.nome}
                        titleTypographyProps={{ align: 'center' }}
                        subheaderTypographyProps={{ align: 'center' }}
                        subheader={"Codigo cliente: "+data.codigoCliente}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="h4" variant="h5"><center>Informações:</center></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Nif</b> <br/>{data.nif}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography style={contaCorrente>0?({
                                    color:'red'
                                }):null}  component="span" variant="subtitle1">
                                    <b>Conta corrente</b> <br/> {contaCorrente.toFixed(2)}€
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Morada</b> <br/>{data.morada}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Codigo</b> Postal <br/>{data.codigoPostal}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Localidade</b> <br/>{data.localidade}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Distrito</b> <br/>{data.distrito}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Linha de preço</b> <br/>{data.linhaPrecos}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="h4" variant="h5"><center>Ações:</center></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button fullWidth={true} onClick={() => setListaEncomendas(true)} type="button" color="primary">Ver encomendas</Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button fullWidth={true} onClick={() => setListaTaras(true)} type="button" color="primary">Ver conta corrente taras</Button>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Link  to={"/clientes/editar/" + props.match.params.id}><Button fullWidth={true} variant={"contained"} color={"primary"}>Editar</Button></Link>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={listaEncomendas}
                onClose={()=>setListaEncomendas(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Lista de encomendas"}</DialogTitle>
                <DialogContent>
                    <p>Filtro</p>
                    <ToggleButtonGroup
                        fulWidth={true}
                        value={filtro}
                        exclusive
                        onChange={(e, fil)=>setFiltro(fil)}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="true" aria-label="left aligned">
                            Liquidadas
                        </ToggleButton>
                        <ToggleButton value="all" aria-label="centered">
                            Todas
                        </ToggleButton>
                        <ToggleButton value="false" aria-label="right aligned">
                            Por liquidar
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Valor</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell>Ver</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {compras.map((item, idx)=>{
                                    switch (filtro) {
                                        case "all":{
                                            return(
                                                <TableRow key={idx}>
                                                    <TableCell>{item.valor}</TableCell>
                                                    <TableCell>{item.pago===true?<>Liquidado</>:<>Por liquidar</>}</TableCell>
                                                    <TableCell><Link to={'/documentos/ver/'+item.id}><Button color="primary" variant="contained">Ver</Button></Link></TableCell>
                                                </TableRow>
                                            )
                                        }
                                        case "true":{
                                            if(item.pago===true){
                                                return(
                                                    <TableRow key={idx}>
                                                        <TableCell>{item.valor}</TableCell>
                                                        <TableCell>Liquidado</TableCell>
                                                        <TableCell><Link to={'/documentos/ver/'+item.id}><Button color="primary" variant="contained">Ver</Button></Link></TableCell>
                                                    </TableRow>
                                                )
                                            }else{
                                                return null
                                            }
                                        }
                                        case "false":{
                                            if(item.pago===false){
                                                return(
                                                    <TableRow key={idx}>
                                                        <TableCell>{item.valor}</TableCell>
                                                        <TableCell>Por liquidar</TableCell>
                                                        <TableCell><Link to={'/documentos/ver/'+item.id}><Button color="primary" variant="contained">Ver</Button></Link></TableCell>
                                                    </TableRow>
                                                )
                                            }else{
                                                return null
                                            }
                                        }

                                        default: return null
                                    }

                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setListaEncomendas(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullScreen={fullScreen}
                open={listaTaras}
                onClose={()=>setListaTaras(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Conta corrente de taras"}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Artigo</TableCell>
                                    <TableCell>Quantidade</TableCell>
                                    <TableCell>Ver</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {taras.map((item, idx)=>{
                                    if(item.quantidade>0){
                                        return(
                                            <TableRow key={idx}>
                                                <TableCell>{item.descricao}</TableCell>
                                                <TableCell>{item.quantidade}</TableCell>
                                                <TableCell><Link to={'/documentos/ver/'+item.id}><Button color="primary" variant="contained">Ver</Button></Link></TableCell>
                                            </TableRow>
                                        )
                                    }else{
                                        return null
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setListaTaras(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
