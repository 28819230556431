import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import * as dayjs from 'dayjs'
import Backdrop from "@material-ui/core/Backdrop";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Swal from "sweetalert2";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function Pagamentos(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState([]);
    const [doc, setDoc] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [linhas, setLinhas] = useState([]);
    const [total, setTotal] = useState(0.00);
    const [clear, setClear] = useState(false);
    const [conf, setConf] = useState(false);

    useEffect(()=>{
        setLoading(true)
        api.get('/cliente').then(res=>{
            if(res.data.code===0){
                setClientes(res.data.data)
            }
            setLoading(false)
        });
    }, [props])

    useEffect(()=>{
        setTotal(0)
        setClear(!clear)
        if(cliente.length!==0){
            setOpen(true)
            api.get('/documentos/pendentes/'+cliente.cliente.id).then(res=>{
                if(res.data.code===0){
                    setDoc(res.data.data);
                    setOpen(false)
                }
            })
        }
    }, [cliente])

    useEffect(()=>{
        if(linhas.length!==0){
            let sub=0;
            for(let row of linhas.selectedRows){
                sub+=row.total
            }
            setTotal(sub)
        }else{
            setTotal(0)
        }
    }, [linhas])

    async function pagar() {
        if(total!==0){
            setClear(!clear)
            setConf(false)
            setOpen(true)
            let err = 0;
            for(let row of linhas.selectedRows){
                api.post('/documentos/Pagamentos', {
                    "valor":row.total,
                    "documento":row.id
                }).then(res=>{
                    if(res.data.code!==0){
                        err=1;
                    }
                })
            }
            setOpen(false)
            if(err===1){
                await Swal.fire({
                    title: "Informação",
                    text: "Ocurreu um erro a processar um documento",
                    icon: "error",
                    timer: 3000
                })
            }else{
                await Swal.fire({
                    title: "Informação",
                    text: "Documentos pagos com sucesso",
                    icon: "success",
                    timer: 3000
                })
                setTotal(0)
                setClear(!clear)
                if(cliente.length!==0){
                    setOpen(true)
                    api.get('/documentos/pendentes/'+cliente.cliente.id).then(res=>{
                        if(res.data.code===0){
                            setDoc(res.data.data);
                            setOpen(false)
                        }
                    })
                }
            }

        }
    }

    const columns = [
        {
            name: "Numero encomenda",
            selector: "id",
            sortable: true
        },
        {
            name: "Total",
            selector: "total",
            sortable: true
        },
        {
            name:"Data",
            cell:row=>{return dayjs(row.data).format('DD/MM/YYYY')}
        }
    ];



    return (
        <Container component="main" maxWidth="md" >
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Select
                options={clientes}
                getOptionLabel={(option)=> {return option.cliente.codigoCliente + "  /  " + option.cliente.nome}}
                getOptionValue ={(option)=>option.cliente.id}
                placeholder={"Cliente"}
                option={cliente}
                onChange={data=>setCliente(data)}
            />
            <div className={classes.paper}>
                <DataTable
                    title="Documentos"
                    columns={columns}
                    data={doc}
                    sortIcon={<SortIcon />}
                    pagination
                    selectableRows
                    selectableRowsComponent={Checkbox}
                    onSelectedRowsChange={(data)=>setLinhas(data)}
                    clearSelectedRows={clear}
                    contextMessage={{
                        singular: 'documento',
                        plural: 'documentos',
                        message: 'selecionado\\s' }}
                    noDataComponent={"Sem documentos"}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                />
            </div>
            <Card>
                <CardContent>
                    <p>Total a pagar: {total.toFixed(2)}€</p>
                    <Button variant="contained" color="primary" onClick={()=> {
                        if(linhas.length!==0){
                            setConf(true)
                        }
                    }}>Pagar: {total.toFixed(2)}€</Button>
                </CardContent>
            </Card>

            <Dialog
                open={conf}
                onClose={()=>setConf(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmação"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Confirma o pagamento de {total.toFixed(2)}€
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setConf(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={pagar} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
