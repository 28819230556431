import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import api, {auth, url} from '../../services/api'
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from "@material-ui/core/TableBody";
import {useHistory} from "react-router-dom"
import Swal from "sweetalert2";
import CardHeader from "@material-ui/core/CardHeader";
import GridList from "@material-ui/core/GridList";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {Box, Collapse, IconButton} from "@material-ui/core";
import EuroIcon from '@material-ui/icons/Euro';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
    root:{
      minWidth: "75vw",
    },
    paper: {
        /*marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',*/
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "20vw",

    }
}));

export default function DocumentosCriar(props){
    const classes = useStyles();
    const [clientes, setClientes] = useState([]);
    const [artigos, setArtigos] = useState([]);
    const [tara, setTara] = useState({value:1, label:"Vendida"});
    const [contaCorrente, setContaCorrente] = useState(0);
    const [linhasArtigo, setLinhasArtigo] = useState([]);
    const [familias, setFamilias] = useState([]);
    const [artigo, setArtigo] = useState([]);
    const [idFamilia, setIdFamilia] = useState("");
    const [cliente, setCliente] = useState({
        "cliente": {
            "id": 0,
            "codigoCliente": "",
            "nome": "",
            "linhaPrecos": 1
        },
        "cc": 0
    });
    const [totalDoc, setTotalDoc] = useState(0.00);
    const [pagamento, setPagamento] = useState(false);
    const [defenirQtd, setDefenirQtd] = useState(false);
    const [defenirPreco, setDefenirPreco] = useState(false);
    const [quantidade, setQuantidade] = useState("");
    const [clienteDefenido, setDefineCliente] = useState(false);
    const [indicarCliente, setIndicarCliente] = useState(false);
    const [edit, setEdit] = useState(0);
    const [preco, setPreco] = useState(0);
    const history = useHistory();
    const [infoArtigo, setInfoArtigo] = useState({
        preco: Number().toFixed(2),
        id: "",
        itemCode: "",
        descricao: "",
        unidade: "",
        estado: "",
        precos:[0,0,0],
        idFamilia: "",
        familia:"",
        Description: "",
        MaximumDecimals: "0",
        UnitOfMeasureID: "",
        quantidade:"",
    });


    useEffect(()=>{
        api.get('/cliente').then(res=>{
            if(res.data.code===0){
                setClientes(res.data.data)
            }
        })
/*        api.get('/item').then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data)
            }
        })*/
        api.get('/familia').then(res=>{
            if(res.data.code===0){
                setFamilias(res.data.data)
            }
        })
    },[props]);


    useEffect(()=>{
        if(idFamilia!==""){
            api.get('/item/familia/'+idFamilia).then(res=>{
                if(res.data.code===0){
                    setArtigos(res.data.data)
                    console.log(res.data.data)
                }
            })
        }
    }, [idFamilia])

    function setQtd(value, qtd){
            if(value!=="."){
                if(value!=="apagar"){
                    setQuantidade(qtd + value);
                }else{
                    setQuantidade(qtd.slice(0, -1));
                }
            }else {
                if (infoArtigo.MaximumDecimals != 0) {
                    if (qtd.indexOf('.') === -1) {
                        if (qtd !== "") {
                            setQuantidade(qtd + value);
                        } else {
                            setQuantidade(0 + value)
                        }
                    }
                }
            }
        if(qtd.indexOf('.') !== -1){
            if(value!=="apagar") {
                const slug = (qtd + value).split('.').pop().length;
                console.log(parseInt(infoArtigo.MaximumDecimals)>parseInt(slug))
                if(parseInt(infoArtigo.MaximumDecimals)<parseInt(slug)){
                    setQuantidade(qtd);
                }
            }
        }

    }

    function setPrice(value, preco){
        if(value!=="apagar"){
            if(preco!==undefined && preco!==""){
                if(value!=='.'){
                    setPreco(preco+value)
                }else{
                    if(preco.indexOf('.') === -1){
                        setPreco(preco+value)
                    }
                }
            }else{
                if(value!=='.'){
                    setPreco(value)
                }else{
                    setPreco(0+".")
                }
            }
        }else{
            setPreco(preco.slice(0, -1))
        }
        if(typeof preco === "string"){
            if(preco.indexOf('.') !== -1){
                if(value!=="apagar") {
                    const slug = (preco + value).split('.').pop().length;
                    if(2<parseInt(slug)){
                        setPreco(preco);
                    }
                }
            }
        }

    }

/*    useEffect(()=>{
        if(cliente.cliente.id!==0){
            console.log(infoArtigo)
            const temp = infoArtigo;
            temp.preco=infoArtigo.precos[cliente.cliente.linhaPreco-1]
            setInfoArtigo(temp);
        }
    }, [cliente, infoArtigo])*/

    function defenirLinhas(quant) {
        let temp = linhasArtigo;
        let total=0.00;
        infoArtigo.tipo=tara.value;
        if(Number(infoArtigo.preco)===0){
            setPreco("");
        }else{
            setPreco(infoArtigo.preco);

        }

        temp.push({
            preco: infoArtigo.preco,
            id: infoArtigo.id,
            itemCode: infoArtigo.itemCode,
            descricao: infoArtigo.descricao,
            unidade: infoArtigo.unidade,
            estado: infoArtigo.estado,
            precos:infoArtigo.precos,
            idFamilia: infoArtigo.idFamilia,
            familia: infoArtigo.familia,
            quantidade:Number(quant).toFixed(parseInt(infoArtigo.MaximumDecimals)),
            tara: infoArtigo.tara,
            tipo:tara.value,
            Description:infoArtigo.Description,
            MaximumDecimals: infoArtigo.MaximumDecimals,
            UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
        });

        if(temp[temp.length -1].preco==0){
            temp[temp.length-1].preco="";
            //alterarPreco(temp.length-1);
            const newTmp=temp.filter((value, index) => {
                if(index===temp.length -1){
                    if(Number(value.preco)==0){
                        value.preco=""
                    }
                    setInfoArtigo(value);
                    const listaTara=[{value:1, label:"Vendida"},{value:2, label:"Emprestada"},{value:3, label:"Oferta"} ];
                    if(value.tipo!==undefined){
                        setTara(listaTara[value.tipo-1])
                    }else{
                        setTara(listaTara[0])
                    }

                }else{
                    return value;
                }
            })
            let total=0.00;
            for(let row of newTmp){
                total+=(parseFloat(row.quantidade) * parseFloat(row.preco))
            }
            setTotalDoc(total)
            //setLinhasArtigo(newTmp);
            setDefenirPreco(true)
            temp=newTmp;
        }else{
            setInfoArtigo({
                preco: Number().toFixed(2),
                id: "",
                itemCode: "",
                descricao: "",
                unidade: "",
                estado: "",
                idFamilia: "",
                familia:"",
                precos:[0,0,0],
                Description: "",
                MaximumDecimals: "0",
                UnitOfMeasureID: "",
                quantidade:"",
            })
        }

        for (let row of temp) {
            if(!isNaN(parseFloat(row.preco))) {
                total += (parseFloat(row.quantidade) * parseFloat(row.preco))
            }else{
                total+=0
            }
        }

        setTotalDoc(total);

        setArtigo({})
        setLinhasArtigo(temp)
    }

    function defineContaCorrente(data) {
        setIndicarCliente(false)
        setContaCorrente(data.cc)
        setCliente(data)
        setDefineCliente(true)
        const tmp = infoArtigo;
        tmp.preco=parseFloat(tmp.precos[data.cliente.linhaPrecos-1]).toFixed(2)
        setInfoArtigo(tmp)
    }

    function defineArtigo(data) {
        setQuantidade("");
        setArtigo(data);
        setDefenirQtd(true);
        data.quantidade = "";
        if(cliente.cliente.linhaPrecos===undefined){
            data.preco=parseFloat(data.precos[0]).toFixed(2)
        }else{
            data.preco=parseFloat(data.precos[cliente.cliente.linhaPrecos-1]).toFixed(2)
        }
        setInfoArtigo(data);
        setTara({value:1, label:"Vendida"});
    }

    function definePreco(value) {
        if(!isNaN(Number(value))){
            if(Number(value)>=0) {
                const temp = linhasArtigo;
                let total=0.00;
                infoArtigo.tipo=tara.value;
                temp.push({
                    preco: value,
                    id: infoArtigo.id,
                    itemCode: infoArtigo.itemCode,
                    descricao: infoArtigo.descricao,
                    unidade: infoArtigo.unidade,
                    estado: infoArtigo.estado,
                    precos:infoArtigo.precos,
                    idFamilia: infoArtigo.idFamilia,
                    familia: infoArtigo.familia,
                    quantidade: infoArtigo.quantidade,
                    tara: infoArtigo.tara,
                    tipo:tara.value,
                    Description:infoArtigo.Description,
                    MaximumDecimals: infoArtigo.MaximumDecimals,
                    UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
                });

                for (let row of temp) {
                    if(!isNaN(parseFloat(row.preco))) {

                        total += (parseFloat(row.quantidade) * parseFloat(row.preco))
                    }else{
                        total+=0
                    }
                }

                setTotalDoc(total);
                setInfoArtigo({
                    preco: Number().toFixed(2),
                    id: "",
                    itemCode: "",
                    descricao: "",
                    unidade: "",
                    estado: "",
                    idFamilia: "",
                    familia:"",
                    precos:[0,0,0],
                    Description: "",
                    MaximumDecimals: "0",
                    UnitOfMeasureID: "",
                    quantidade:"",
                });
                setArtigo({});
                setLinhasArtigo(temp);
                setDefenirPreco(false);
            }
        }
    }

    function defineQuantidade(value) {
        if(value>=0){
            setInfoArtigo({
                preco: infoArtigo.preco,
                id: infoArtigo.id,
                itemCode: infoArtigo.itemCode,
                descricao: infoArtigo.descricao,
                unidade: infoArtigo.unidade,
                estado: infoArtigo.estado,
                idFamilia: infoArtigo.idFamilia,
                familia: infoArtigo.familia,
                quantidade:value,
                tara: infoArtigo.tara,
                precos: infoArtigo.precos,
                Description:infoArtigo.Description,
                MaximumDecimals: infoArtigo.MaximumDecimals,
                UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
            })
            defenirLinhas(value);
            setDefenirQtd(false)
        }
    }

    function alteraTara(data) {
        if(data.value!==1){
            setInfoArtigo({
                preco: 0.00,
                id: infoArtigo.id,
                itemCode: infoArtigo.itemCode,
                descricao: infoArtigo.descricao,
                unidade: infoArtigo.unidade,
                estado: infoArtigo.estado,
                idFamilia: infoArtigo.idFamilia,
                familia:infoArtigo.familia,
                precos: infoArtigo.precos,
                quantidade:infoArtigo.quantidade,
                tara:infoArtigo.tara,
                Description:infoArtigo.Description,
                MaximumDecimals: infoArtigo.MaximumDecimals,
                UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
            })
        }
        setTara(data)
    }

    async function guardarDoc(pago) {
        const data={};
        data.cliente=cliente.cliente.id;
        data.pago=pago
        data.linhas=linhasArtigo;
        const res =await api.post('/documentos', data);
        setPagamento(false)
        if(res.data.code===0){
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon:"success",
                showDenyButton: true,
                confirmButtonText:"Obter documento",
                denyButtonText:"Fechar",
            }).then(r=>{
                if(r.isConfirmed){
                    window.open(url+"pdf/docVenda/"+res.data.data+"/"+auth(), '_blank')
                    history.push("/documentos/ver/"+res.data.data)

                }else{
                    history.push("/documentos/ver/"+res.data.data)
                }
            })
        }else{
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon: "error",
                timer: 3000
            })
        }

    }

    function alterarPreco(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if(index===idx){
                if(Number(value.preco)==0){
                    value.preco=""
                }
                setInfoArtigo(value);
                const listaTara=[{value:1, label:"Vendida"},{value:2, label:"Emprestada"},{value:3, label:"Oferta"} ];
                if(value.tipo!==undefined){
                    setTara(listaTara[value.tipo-1])
                }else{
                    setTara(listaTara[0])
                }

            }else{
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=(parseFloat(row.quantidade) * parseFloat(row.preco))
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);
        setDefenirPreco(true)
    }

    function removerLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if (index !== idx) {
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=(parseFloat(row.quantidade) * parseFloat(row.preco))
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);
    }

    function removeZerosArtigo() {
        if(infoArtigo.preco===0 || infoArtigo.preco==="0.00"){
            setInfoArtigo({
                preco: "",
                id: infoArtigo.id,
                itemCode: infoArtigo.itemCode,
                descricao: infoArtigo.descricao,
                unidade: infoArtigo.unidade,
                estado: infoArtigo.estado,
                idFamilia: infoArtigo.idFamilia,
                familia:infoArtigo.familia,
                precos: infoArtigo.precos,
                quantidade:infoArtigo.quantidade,
                tara:infoArtigo.tara,
                Description:infoArtigo.Description,
                MaximumDecimals: infoArtigo.MaximumDecimals,
                UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
            })
        }
    }

    return(
        <Container component="main" maxWidth="xl">
            <CssBaseline />
            <div className={classes.paper}>
{/*                <Typography component="h1" variant="h5">
                    Criar encomenda <br/>
                </Typography>*/}
                <Grid container spacing={1}>
                    <Grid item sm={7} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item sm={12} xs={12}>
                                <Card variant="outlined">
                                    <CardHeader
                                        title={"Familias"}
                                    />
                                    <CardContent>
                                        <GridList container sm={12} xs={12} style={{flexWrap: 'nowrap', overflowX: 'scroll', transform: 'translateZ(0)', maxWidth: '57vw'}} cols={2.5} spacing={1}>
                                            {familias.map((row, idx)=><Grid style={{margin: '1px',height: '90px'}} key={idx} item sm={3}><Button disabled={idFamilia==row.id} onClick={()=>setIdFamilia(row.id)} style={{width: '100%', height:'100%'}} variant={"contained"} color={"primary"}>{row.descricao}</Button></Grid>)}
                                        </GridList>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid  item sm={12} xl={12}>
                                <Card  variant="outlined">
                                    <CardHeader
                                        title={"Artigos"}
                                    />
                                    <CardContent>
                                        <GridList style={{maxHeight:'51vh'}} spacing={5} cols={4}>
                                            {artigos.map((row, idx)=><Grid style={{height: '90px'}} key={idx} item sm={3}><Button onClick={()=>defineArtigo(row)} style={{width: '100%', height:'100%'}} variant={"contained"} color={"primary"}>{row.descricao}</Button></Grid>)}
                                        </GridList>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={5} xs={12} style={{width: '36vw'}}>
                        <Card  style={{height: '65vh'}}  variant="outlined">
                            <CardHeader
                                title={"Documento"}
                                subheader={"Cliente: "+cliente.cliente.nome}
                            />
                            <CardContent>
                                <TableContainer style={{height: '50vh', overflowY: 'auto', position: "sticky"}}>
                                    <Table  stickyHeader className={classes.table} style={{maxHeight: '50vh', overflowY: 'visible', position: "sticky"}} aria-label="Artigos do documento">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell/>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell >Preço</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                                <TableCell>Preço total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {linhasArtigo.map((row, index) =>
                                                <React.Fragment key={index}>
                                                <TableRow>
                                                    <TableCell><IconButton aria-label="expand row" size="small" onClick={() => {edit===0?setEdit(row.id):setEdit(0)}}>
                                                        {edit===row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton></TableCell>
                                                    <TableCell>{row.descricao}</TableCell>
                                                    <TableCell><Button variant={"text"} onClick={()=>alterarPreco(index)}>{isNaN(parseFloat(row.preco))?"0.00":parseFloat(row.preco).toFixed(2)}€/{row.UnitOfMeasureID}</Button></TableCell>
                                                    <TableCell>{row.quantidade}</TableCell>
                                                    <TableCell>{isNaN(parseFloat(row.quantidade) * parseFloat(row.preco))?"0.00":(parseFloat(row.quantidade) * parseFloat(row.preco)).toFixed(2)}€</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}  colSpan={5}>
                                                        <Collapse in={edit===row.id} timeout="auto" unmountOnExit>
                                                            <Box margin={1}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    Menu
                                                                </Typography>
                                                                <Table>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell><Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={() =>removerLinha(index)}>Remover linha</Button></TableCell>
                                                                            <TableCell><Button variant="contained" color="primary" onClick={()=>alterarPreco(index)}>Alterar preco</Button></TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                                </React.Fragment>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                        <Card  variant="outlined" style={{marginTop: 10}}>
                            <CardContent>
                                <TableContainer >
                                    <Typography variant="h6" gutterBottom component="div">
                                        <b>TOTAL: {totalDoc.toFixed(2)}€</b>
                                    </Typography>
                                    <Typography variant="p" gutterBottom component="div">
                                        Linha de preços em uso: {cliente.cliente.linhaPrecos}
                                    </Typography>
                                    <Table className={classes.table} >
                                        <TableBody>
                                            <TableCell><Button onClick={()=>setPagamento(true)}  disabled={linhasArtigo.length<= 0 || !clienteDefenido===true} variant={"outlined"} color={"primary"} startIcon={<EuroIcon/>}>Fechar venda</Button></TableCell>
                                            <TableCell><Button onClick={()=>setIndicarCliente(true)} variant={"outlined"} startIcon={<PersonIcon/>}>Indicar Cliente</Button></TableCell>
                                            <TableCell><Button onClick={()=>
                                                Swal.fire({
                                                    title: 'Deseja cancelar a venda?',
                                                    showDenyButton: true,
                                                    confirmButtonText: `Sim`,
                                                    denyButtonText: `Não`,
                                                }).then((result) => {
                                                    /* Read more about isConfirmed, isDenied below */
                                                    if (result.isConfirmed) {
                                                        window.location.reload()
                                                    }
                                                })
                                                } color={"secondary"} variant={"outlined"} startIcon={<DeleteIcon/>}>Cancelar venda</Button></TableCell>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={pagamento}
                onClose={()=>setPagamento(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Guardar documento?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O documento dá no total {totalDoc.toFixed(2)}€ deseja colocar em conta corrente ou pagar agora?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setPagamento(false)} color="primary">
                        Cancelar ação
                    </Button>
                    <Button onClick={()=>guardarDoc(false)} disabled={totalDoc<=0} color="primary" autoFocus>
                        Conta corrente
                    </Button>
                    <Button onClick={()=>guardarDoc(true)} color="primary" autoFocus>
                        Pagamento agora
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={defenirQtd}
                onClose={()=>setDefenirQtd(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Quantidade do artigo: "+infoArtigo.descricao}
                {infoArtigo.tara==1?(<Select style={{marginTop: 20, marginLeft: 20}} value={tara} option={{value:1, label:"Vendida"}} onChange={alteraTara} options={[{value:1, label:"Vendida"},{value:2, label:"Emprestada"},{value:3, label:"Oferta"} ]} variant="outlined" name="tara" placeholder="Tara"/>):null}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{width:'100%', height:'75px', fontSize:'29px'}}>{quantidade}</Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("1", quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>1</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("2", quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>2</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("3", quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>3</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("4", quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>4</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("5", quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>5</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("6", quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>6</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("7", quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>7</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("8", quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>8</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("9", quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>9</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("apagar", quantidade)} variant={"contained"} color={"secondary"} style={{width:'100%', height:'75px', fontSize:'25px'}}><b>Apagar</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd("0", quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>0</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setQtd(".", quantidade)} disabled={infoArtigo.MaximumDecimals=="0"} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>.</b></Button></Grid>
                        <Grid item xs={12}><Button disabled={quantidade==="" || Number(quantidade)===0} color={"primary"} onClick={()=>defineQuantidade(quantidade)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>Adicionar artigo</b></Button></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                open={defenirPreco}
                onClose={()=>definePreco(preco)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Alterar preço unitario"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{width:'100%', height:'75px', fontSize:'29px'}}>{preco}</Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("1", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>1</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("2", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>2</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("3", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>3</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("4", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>4</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("5", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>5</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("6", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>6</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("7", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>7</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("8", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>8</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("9", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>9</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("apagar", preco)} variant={"contained"} color={"secondary"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>Apagar</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice("0", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>0</b></Button></Grid>
                        <Grid item xs={4}><Button onClick={()=>setPrice(".", preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>.</b></Button></Grid>
                        <Grid item xs={12}><Button disabled={preco==="" || Number(preco)===0} color={"primary"} onClick={()=>definePreco(preco)} variant={"contained"} style={{width:'100%', height:'60px', fontSize:'25px'}}><b>Atualizar</b></Button></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                open={indicarCliente}
                onClose={()=>setIndicarCliente(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Indicar cliente"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{height:'400px'}}>
                        <Grid item xs={12} sm={12}>
                            <Select
                                onChange={defineContaCorrente}
                                className={classes.select}
                                name="clientes"
                                placeholder="Clientes"
                                options={clientes}
                                option={cliente}
                                getOptionLabel={(option)=> option.cliente.nome}
                                getOptionValue ={(option)=>option.cliente.id}
                                defaultMenuIsOpen
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Container>
    )
}
