import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function UtilizadoresListar() {
    const classes = useStyles();

    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = React.useState(false);

    const getData=async ()=>{
        setLoading(true)
        const res = await api.get('/user');
        if(res.data.code===0){
            setClientes(res.data.data)
        }
        setLoading(false)
    }

    useEffect(()=>{
        getData()
    }, [])

    const columns = [
        {
            name: "Nome",
            selector: "nome",
            sortable: true
        },
        {
            name: "Nome utilizador",
            selector: "username",
            sortable: true
        },
        {
            name:"",
            cell:row=><Link to={"/utilizadores/" + row.id}><Button variant={"contained"} color={"primary"}>Ficha</Button></Link>
        }
    ];



    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <DataTable
                    title="Utilizadores"
                    columns={columns}
                    data={clientes}
                    sortIcon={<SortIcon />}
                    pagination
                    //selectableRows
                    selectableRowsComponent={Checkbox}
                    contextMessage={{
                        singular: 'artigo',
                        plural: 'artigos',
                        message: 'selecionada\\s' }}
                    noDataComponent={"Não foi encontrados clientes"}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                />
            </div>
        </Container>
    );
}
