import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api, {auth, url} from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function DocumentosListarPorPagar() {
    const classes = useStyles();

    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = React.useState(false);

    const getData=async ()=>{
        setLoading(true)
        const res = await api.get('documentos/pendentes');
        if(res.data.code===0){
            setClientes(res.data.data)
        }
        setLoading(false)
    }

    useEffect(()=>{
        getData()
    }, [])

    const columns = [
        {
            name: "Cliente",
            selector: "cliente",
            sortable: true
        },
        {
            name: "Valor",
            selector: "total",
            sortable: true
        },
        {
            name:"",
            cell:row=><Link to={"/documentos/ver/" + row.id}><Button variant={"contained"} color={"primary"}>Detalhes</Button></Link>
        },
        {
            name:"",
            cell:row=><a target={"_blank"} rel="noopener noreferrer" href={url+"pdf/docVenda/"+ row.id+"/"+auth()}><Button variant="contained" color="primary" fullWidth={true}>Obter documento</Button></a>
        }
    ];



    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <DataTable
                    title="Encomendas por pagar"
                    columns={columns}
                    data={clientes}
                    sortIcon={<SortIcon />}
                    pagination
                    //selectableRows
                    selectableRowsComponent={Checkbox}
                    noDataComponent={"Não foi encontrados clientes"}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                />
            </div>
        </Container>
    );
}
