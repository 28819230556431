import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 345,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function FamiliaFicha(props) {
    const classes = useStyles();
    const [open, ] = useState(false);
    const [data, setData] = useState({
        descricao:""
    });
    const [artigos, setArtigos] = useState([]);

    useEffect(()=>{
        api.get('/familia/'+props.match.params.id).then(res=>{
            if(res.data.data!==[]) {
                setData(res.data.data[0])
            }
        })
        api.get('/item/familia/'+props.match.params.id).then(res=>{
            if(res.data.data!==[]){
                setArtigos(res.data.data)
            }
        })
    }, [props.match.params.id])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography component="h1" variant="h5">
                            Ficha da familia: {data.descricao}
                        </Typography>
                        <Typography fullWidth={true} component="h1" variant="h6">
                            Artigos:
                        </Typography>
                        <List component="nav" >
                            {artigos.map((value, index) => {
                                return(
                                    <Link to={"/artigos/"+value.id}  key={index}>
                                        <ListItem button >
                                            <ListItemText primary={value.descricao} />
                                        </ListItem>
                                    </Link>
                                )
                            })}

                        </List>
                        <center>
                            <Link to={"/familias/editar/" + data.id}><Button variant={"contained"} color={"primary"}>Editar</Button></Link>
                        </center>
                    </CardContent>
                </Card>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
