

export function login  (user, token, nome){
    localStorage.setItem(TOKEN_KEY, token)
    localStorage.setItem(USER_KEY, user)
    localStorage.setItem(Nome_KEY, nome)
    return null
}

export const TOKEN_KEY ="@gestor-Token";
export const USER_KEY ="@gestor-User";
export const Nome_KEY ="@gestor-Nome";



export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null && localStorage.getItem(USER_KEY) !== null;

export const username = () => localStorage.getItem(USER_KEY);

export const nome = () => localStorage.getItem(Nome_KEY);

export const getSession = ()=>{
    if(isAuthenticated()){
        return {
            status: true,
            data: {
                user: localStorage.getItem(USER_KEY),
                token: localStorage.getItem(TOKEN_KEY)
            }
        }
    }else{
        return {
            status: false,
            data: {
                user: "",
                token: ""
            }
        }
    }
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
};
