import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link, useHistory} from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 345,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ArtigoFicha(props) {
    const classes = useStyles();
    const [open, ] = useState(false);
    const [data, setData] = useState({
        Description: "",
        MaximumDecimals: "",
        UnitOfMeasureID: "",
        descricao: "",
        familia: "",
        id: 7,
        idFamilia: "",
        itemCode: "",
        precos: [0, 0, 0],
        tara: 0,
    });

    const history = useHistory();

    useEffect(()=>{
        api.get('/item/'+props.match.params.id).then(res=>{
            setData(res.data.data)
        })
    }, [props.match.params.id])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Artigo: "+data.descricao}
                        titleTypographyProps={{ align: 'center' }}
                        subheaderTypographyProps={{ align: 'center' }}
                        subheader={"Codigo do artigo: "+data.itemCode}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="h4" variant="h5"><center>Informações:</center></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Familia</b> <br/><Link to={"/familias/"+data.idFamilia}><u>{data.familia}</u></Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Unidade de venda</b> <br/>{data.Description}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="span" variant="subtitle1">
                                    <center><b> Preço</b></center>
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Linha de preço</TableCell>
                                                <TableCell>Preços</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.precos.map((value, index) => (
                                                <TableRow>
                                                    <TableCell>{index+1}</TableCell>
                                                    <TableCell>{value}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h4" variant="h5"><center>Ações:</center></Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Link to={"/artigos/editar/" + data.id}><Button fullWidth={true} variant={"contained"} color={"primary"}>Editar</Button></Link>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
