import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function FamiliaListar() {
    const classes = useStyles();
    const theme = useTheme();

    const [familias, setFamilias] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [dados, setDados] = useState({descricao:"", id:""});
    const [message, setMessage] = useState({message:"", type:"", show:false});

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const getData=async ()=>{
        setLoading(true)
        const res = await api.get('/familia');
        if(res.data.code===0){
            setFamilias(res.data.data)
        }
        setLoading(false)
    }

    useEffect(()=>{
        getData()
    }, [])

    async function apagarFamilia(descricao, id){
        setDados({
            descricao,
            id
        })
        setOpenDialog(true)
    }

    const columns = [
        {
            name: "ID",
            selector: "id",
            sortable: true
        },
        {
            name: "Descricão",
            selector: "descricao",
            sortable: true
        },
        {
            name:"",
            cell:row=><Link to={"/familias/" + row.id}><Button variant={"contained"} color={"primary"}>Ficha</Button></Link>

        },{
            name:"",
            cell:row=><Link to={"/familias/editar/" + row.id}><Button variant={"contained"} color={"primary"}>Editar</Button></Link>
        },
        {
            name:"",
            cell:row=><Button onClick={() =>apagarFamilia(row.descricao, row.id)} variant={"outlined"} color={"primary"}>Apagar familia</Button>
        }
    ];

    async function apagar(id) {
        const res = await api.delete(`/familia/${id}`)
        if(res.data.code===0){
            setOpenDialog(false)
            setMessage({
                message: res.data.message,
                type: 'success',
                show: true
            })
            await getData()
        }else{
            setOpenDialog(false)
            setMessage({
                message: res.data.message,
                type: 'error',
                show: true
            })
        }
    }


    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <DataTable
                    title="Familias"
                    columns={columns}
                    data={familias}
                    sortIcon={<SortIcon />}
                    pagination
                    //selectableRows
                    selectableRowsComponent={Checkbox}
                    contextMessage={{
                        singular: 'familia',
                        plural: 'familias',
                        message: 'selecionada\\s' }}
                    noDataComponent={"Não foi encontradas familias"}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                />
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Confirmação"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja apagar a familia: {dados.descricao}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={()=>apagar(dados.id)} color="primary" autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{vertical:'bottom', horizontal:'left'}} open={message.show} autoHideDuration={6000} onClose={()=>setMessage({message:"", type:"success", show:false})}>
                <Alert onClose={()=>setMessage({message:"", type:"success", show:false})} severity={message.type}>
                    {message.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}
