import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from '@material-ui/core/styles';
import ArtigosCriar from "./artigos/Criar";
import ClienteCriar from "./clientes/Criar";
import TextField from "@material-ui/core/TextField"
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Select from "react-select";
import DevolverTaras from "./taras/DevolverTara";
import {Paper} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '80vw',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    height:{
        backgroundColor:"transparent",
        alignContent: "center",
        color:"white",
        height: 50,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Dashboard(props) {
    const classes = useStyles();
    const [open, ] = useState(false);
    const [criarArtigo, setCriarArtigo] = useState(false);
    const [criaCliente, setCriarCliente] = useState(false);
    const [entregaTaras, setEntregaTaras] = useState(false);
    const [pagamento, setPagamento] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState([]);
    const [encomendasPendentes, setEncomendasPendentes] = useState([]);
    const [encomendaPendente, setEncomendaPendente] = useState([]);
    const [labelEncomendas, setLabelEncomendas] = useState();
    const [valorPagamento, setValorPagamento] = useState("");
    const theme = useTheme();
    const [alert, setAlert] = useState({open:false, message:"", type:"info"});
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(()=>{
        api.get('/cliente').then(res=>{
            if(res.data.code===0){
                setClientes(res.data.data);
            }
        })
    }, [props])

    useEffect(()=>{
        if(cliente.length!==0){
            console.log(cliente)
            api.get('/documentos/pendentes/'+cliente.cliente.id).then(res=>{
                if(res.data.code===0){
                    setEncomendasPendentes(res.data.data);
                    setLabelEncomendas(null);
                }
            })
        }
    }, [cliente])

    function realizarPagamento() {
        if(cliente.cliente.id!==undefined && encomendaPendente.id!==undefined){
            api.post('/documentos/Pagamentos', {
                valor:valorPagamento,
                documento:encomendaPendente.id
            }).then(res=>{
                if(res.data.code===0){
                    setAlert({
                        open: true,
                        message: res.data.message,
                        type: 'success'
                    })
                }else{
                    setAlert({
                        open: true,
                        message: res.data.message,
                        type: 'error'
                    })
                }
            })
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography component="h1" variant="h5">
                            Dashboard
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                    <Link to={"/documentos"}>
                                        <Button fullWidth={true} variant={"contained"} color={"primary"}>
                                            <Paper elevation={0} className={classes.height} />
                                            <Typography variant={"h5"} component="h2">Realizar encomenda</Typography>
                                        </Button>
                                    </Link>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                               <Button fullWidth={true} variant={"contained"} onClick={()=>setEntregaTaras(true)} color={"primary"}>
                                   <Paper elevation={0} className={classes.height} />
                                   <Typography variant={"h5"} component="h2">Devolução de taras</Typography>
                               </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button fullWidth={true} variant={"contained"} color={"primary"} onClick={()=>setPagamento(true)}>
                                    <Paper elevation={0} className={classes.height} />
                                    <Typography variant={"h5"} component="h2">Realizar pagamento</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button fullWidth={true} onClick={()=>setCriarCliente(true)} variant={"contained"} color={"primary"}>
                                    <Paper elevation={0} className={classes.height} />
                                    <Typography variant={"h5"} component="h2">Criar cliente</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button fullWidth={true} onClick={()=>setCriarArtigo(true)} variant={"contained"} color={"primary"}>
                                    <Paper elevation={0} className={classes.height} />
                                    <Typography variant={"h5"} component="h2">Adicionar artigo</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* DIALOGS */}

            {/* Adicionar artigo */}
            <Dialog
                fullScreen={fullScreen}
                open={criarArtigo}
                onClose={()=>setCriarArtigo(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <ArtigosCriar/>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setCriarArtigo(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Criar cliente */}
            <Dialog
                fullScreen={fullScreen}
                open={criaCliente}
                onClose={()=>setCriarCliente(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <ClienteCriar/>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setCriarCliente(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Entrega de taras */}
            <Dialog
                fullScreen={fullScreen}
                open={entregaTaras}
                onClose={()=>setEntregaTaras(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <DevolverTaras/>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setEntregaTaras(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Realizar pagamento */}
            <Dialog
                fullScreen={fullScreen}
                open={pagamento}
                onClose={()=>setPagamento(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <div className={classes.paper}>
                        <Typography component="h1" variant="h5">
                            Realizar pagamento
                        </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Select
                                        options={clientes}
                                        getOptionLabel={option => option.cliente.nome}
                                        getOptionValue={option => option.cliente.id}
                                        placeholder={"Selecionar cliente"}
                                        option={cliente}
                                        onChange={(data)=>setCliente(data)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Select
                                        options={encomendasPendentes}
                                        getOptionLabel={option => "Encomenda numero "+option.id+ " Falta: "+option.total}
                                        getOptionValue={option => option.id}
                                        isDisabled={cliente.length===0}
                                        placeholder={"Selecionar encomenda"}
                                        option={encomendaPendente}
                                        value={labelEncomendas}
                                        onChange={(data)=> {
                                            setLabelEncomendas(undefined)
                                            setEncomendaPendente(data)
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={encomendaPendente.length===0}
                                        name="preco"
                                        required
                                        value={valorPagamento}
                                        onChange={(e)=>{
                                            if(e.target.value<=encomendaPendente.total){
                                                setValorPagamento(e.target.value)
                                            }
                                        }}
                                        fullWidth
                                        label="Valor a pagar"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        fullWidth
                                        disabled={encomendaPendente.length===0}
                                        onClick={()=>setValorPagamento(encomendaPendente.total)}
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Valor total
                                    </Button>
                                </Grid>
                            </Grid>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={realizarPagamento}
                            >
                                Realizar pagamento
                            </Button>
                        <Collapse in={alert.open} >
                            <Alert severity={alert.type}>
                                {alert.message}
                            </Alert>
                        </Collapse>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setPagamento(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
